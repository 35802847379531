<template>
	<div>
		<!-- 内容 -->
		<div class="orderdetailsbox">
			<div class="setservice">
				<!-- 暂时隐藏 -->
				<div class="setservice_item" style="display: none;">
					<div class="orderdetailstitle setitle clearfloat"><h5>仅退款</h5></div>
					<div class="setservicebox">
						<div class="setservicelist clearfloat">
							<label class="setservicetext">默认退款选项：</label>
							<div class="setserviceright">
								<div class="setchecked">
									<template>
										<el-radio v-model="setservices.refund" v-for="(item,index) in radiolist" :key="index" :label="item.value">{{item.label}}</el-radio>
									</template>
								</div>
								<div class="setip">
									开启后，买家可以选择默认退款原因选项（必选） 
									<el-tooltip class="boxicon" effect="light" placement="right">
										<div slot="content" class="setipimg"><img src="../../assets/images/shili.png" alt="" slot="placeholder"></div>
										<span>示例</span>
									</el-tooltip>
								</div>
							</div>
						</div>
						<!-- 点击开始显示end -->
						<div class="setservicelist clearfloat">
							<label class="setservicetext">退款说明字数限制：</label>
							<div class="setserviceright">
								<div class="setserviceinp">
									至少 <el-input-number :disabled="checked == true ? true : false" :controls="false" v-model="setservices.refundtext" :max="999999" :min="1" ></el-input-number> 个字
								</div>
								<p class="setcheckbox">
									<el-checkbox v-model="checked">不限制</el-checkbox>
								</p>
							</div>
						</div>
						<div class="setservicelist clearfloat">
							<label class="setservicetext">图片：</label>
							<div class="setserviceright">
								<div class="setserviceinp">
									至少 <el-input-number :disabled="imgchecked == true ? true : false" :controls="false" v-model="setservices.refundimg" :max="5" :min="1" ></el-input-number> 张图片
								</div>
								<p class="setcheckbox">
									<el-checkbox v-model="imgchecked">不限制</el-checkbox>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="setservice_item">
					<div class="orderdetailstitle setitle clearfloat"><h5>售后</h5></div>
					<div class="setservicebox">
						<div class="setservicelist clearfloat">
							<label class="setservicetext">默认售后选项：</label>
							<div class="setserviceright">
								<div class="setchecked">
									<template>
										<el-radio v-model="setservices.refund_exchange" v-for="(item,index) in radiolist" :key="index" :label="item.value">{{item.label}}</el-radio>
									</template>
								</div>
								<div class="setip">
									开启后，买家可以选择默认售后原因选项（必选） 
									<el-tooltip class="boxicon" effect="light" placement="right">
										<div slot="content" class="setipimg"><img src="../../assets/images/shili.png" alt=""></div>
										<span>示例</span>
									</el-tooltip>
								</div>
							</div>
						</div>
						<!-- 点击开始显示end -->
						<div class="setservicelist clearfloat">
							<label class="setservicetext">售后说明字数限制：</label>
							<div class="setserviceright">
								<div class="setserviceinp">
									至少 <el-input-number :disabled="checked1 == true ? true : false" :controls="false" v-model="setservices.refundextext" :max="999999" :min="1" ></el-input-number> 个字
								</div>
								<p class="setcheckbox">
									<el-checkbox v-model="checked1">不限制</el-checkbox>
								</p>
							</div>
						</div>
						<div class="setservicelist clearfloat">
							<label class="setservicetext">图片：</label>
							<div class="setserviceright">
								<div class="setserviceinp">
									至少 <el-input-number :disabled="imgchecked1 == true ? true : false" :controls="false" v-model="setservices.refundeximg" :max="999999" :min="1" ></el-input-number> 张图片
								</div>
								<p class="setcheckbox">
									<el-checkbox v-model="imgchecked1">不限制</el-checkbox>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="setservice_item" v-if="false">
					<div class="orderdetailstitle setitle clearfloat"><h5>换货</h5></div>
					<div class="setservicebox">
						<div class="setservicelist clearfloat">
							<label class="setservicetext">默认退款选项：</label>
							<div class="setserviceright">
								<div class="setchecked">
									<template>
									  <el-radio v-model="setservices.reexchange" v-for="(item,index) in radiolist" :key="index" :label="item.value">{{item.label}}</el-radio>
									</template>
								</div>
								<div class="setip">
									开启后，买家可以选择默认退款原因选项（必选） 
									<el-tooltip class="boxicon" effect="light" placement="right">
										<div slot="content" class="setipimg"><img src="../../assets/images/shili.png" alt=""></div>
										<span>示例</span>
									</el-tooltip>
								</div>
							</div>
						</div>
						<!-- 点击开始显示end -->
						<div class="setservicelist clearfloat">
							<label class="setservicetext">退款说明字数限制：</label>
							<div class="setserviceright">
								<div class="setserviceinp">
									至少 <el-input-number :disabled="checked2 == true ? true : false" :controls="false" v-model="setservices.reexchangetext" :max="999999" :min="1" ></el-input-number> 个字
								</div>
								<p class="setcheckbox">
									<el-checkbox v-model="checked2">不限制</el-checkbox>
								</p>
							</div>
						</div>
						<div class="setservicelist clearfloat">
							<label class="setservicetext">图片：</label>
							<div class="setserviceright">
								<div class="setserviceinp">
									至少 <el-input-number :disabled="imgchecked2 == true ? true : false" :controls="false" v-model="setservices.reexchangeimg" :max="5" :min="1" ></el-input-number> 张图片
								</div>
								<p class="setcheckbox">
									<el-checkbox v-model="imgchecked2">不限制</el-checkbox>
								</p>
							</div>
						</div>
					</div>
				</div>
				<!-- 暂时隐藏误删 -->
				<div class="setservice_item">
					<div class="orderdetailstitle setitle clearfloat"><h5>售后原因</h5></div>
					<div class="setservice_itembox">
						<div class="setservice_itemboxlist">
							<el-form ref="priceForm" :model="priceForm" label-width="160px">
								<el-form-item label="售后原因" prop="specifi">
									<div>
										<div class="setservicelisted clearfloat" v-for="(item,index) in priceForm.specifi"
											:key="index">
											<div class="speced-item d-flex">
												<el-input maxlength="20" placeholder="请输入售后原因"
													v-model="item.reason" show-word-limit></el-input>
											</div>
											<span class="servicedelect" @click="delSpec(index)">删除</span>
										</div>
										<div class="serviceadd">
											<!-- <div class="goodsbtn" @click="appendSpec">添加售后原因</div> -->
											<el-button  @click="appendSpec">添加售后原因</el-button>
										</div>
									</div>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
			</div>
			<diy-footer-button v-if="hasPerm(['order.aftersale.setting.update'])">
			    <el-button type="primary"  @click="setsave()">保存</el-button>
			</diy-footer-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				radio: 0,
				radio1: 0,
				radio2: 0,
				checked: true,
				imgchecked:false,
				checked1: true,
				imgchecked1:false,
				checked2: true,
				imgchecked2:false,
				setservices:{
					
				},
				priceForm: {
					specifi: [{
						reason: '',//售后原因
					}], //售后原因
				},
				radiolist: [{
					label: '关闭',
					value: 0,
				},
				{
					label: '开启',
					value: 1,
				}],
				setservicecon: '',
			};
		},
		created() {
		},
		mounted() {
			this.setserviceapi();
		},
		methods: {
			
			setserviceapi(){
				this.$get(this.$apis.setservice).then(res => {
					if (res.code == 200) {
						this.setservicecon = res.data;
						this.setservices = {
							"refund" : res.data['order.refund.open'],
							"refundtext" : res.data['order.refund.des_min'],
							"refundtextlimit" : res.data['order.refund.des_limit'],
							"refundimg" : res.data['order.refund.img_min'],
							"refundimglimit" : res.data['order.refund.img_limit'],
							"refund_exchange" : res.data['order.refund_exchange.open'],
							"refundextext" : res.data['order.refund_exchange.des_min'],
							"refundextextlimit" : res.data['order.refund_exchange.des_limit'],
							"refundeximg" : res.data['order.refund_exchange.img_min'],
							"refundeximglimit" : res.data['order.refund_exchange.img_limit'],
							
							"reexchange" : res.data['order.exchange.open'],
							"reexchangetext" : res.data['order.exchange.des_min'],
							"reexchangetextlimit" : res.data['order.exchange.des_limit'],
							"reexchangeimg" : res.data['order.exchange.img_min'],
							"reexchangeimglimit" : res.data['order.exchange.img_limit'],
							"specifi" : res.data['order.refund.reason.list']
						}
						this.priceForm.specifi = this.setservices.specifi;
						this.checked = this.setservices.refundtextlimit == 1 ? true : false;
						this.imgchecked = this.setservices.refundimglimit == 1 ? true : false;
						this.checked1 = this.setservices.refundextextlimit == 1 ? true : false;
						this.imgchecked1 = this.setservices.refundeximglimit == 1 ? true : false;
						this.checked2 = this.setservices.reexchangetextlimit == 1 ? true : false;
						this.imgchecked2 = this.setservices.reexchangeimglimit == 1 ? true : false;
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},
			
			// 添加售后原因
			appendSpec() {
				let arr = {
					reason: ''
				};
				if(this.priceForm.specifi.length>=25){
					this.$message({
						type: 'warning',
						message: '最多可添加25条'
					});
					return
				}
				this.priceForm.specifi.push(arr);
			},
			// 删除售后原因
			delSpec(index) {
				let that = this;
				that.$message({
					type: 'success',
					message: '删除成功!'
				});
				that.priceForm.specifi.splice(index, 1)
			},
			// 保存
			setsave(){
				let data = {
					// "order.refund.open": this.setservices.refund,
					// "order.refund.des_limit": this.checked == true ? 1 : 0,
					// "order.refund.des_min": this.setservices.refundtext,
					// "order.refund.img_limit": this.setservices.refundimglimit,
					// "order.refund.img_min": this.imgchecked == true ? 1 : 0,
					"order.refund.open": 1,
					"order.refund.des_limit": 1,
					"order.refund.des_min": 1,
					"order.refund.img_limit": 1,
					"order.refund.img_min": 1,
					
					"order.refund_exchange.open": this.setservices.refund_exchange,
					"order.refund_exchange.des_limit": this.checked1 == true ? 1 : 0,
					"order.refund_exchange.des_min": this.setservices.refundextext,
					"order.refund_exchange.img_limit": this.imgchecked1 == true ? 1 : 0,
					"order.refund_exchange.img_min": this.setservices.refundeximg,
					// "order.exchange.open": this.setservices.reexchange,
					// "order.exchange.des_limit": this.checked2 == true ? 1 : 0,
					// "order.exchange.des_min": this.setservices.reexchangetext,
					// "order.exchange.img_limit": this.imgchecked2 == true ? 1 : 0,
					// "order.exchange.img_min": this.setservices.reexchangeimg,
					// "order.refund.reason.list": this.priceForm.specifi
					"order.refund.reason.list": this.setservices.specifi
				};
				// //console.log(data)
				// return false;
				this.$put(this.$apis.setservicesave,data).then(res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							duration: 1500,
							message: '设置成功',
							onClose :()=>{
								this.setserviceapi();
							}
						});
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},
		}
	};
</script>
<style scoped>
	@import url("css/order.css");
	.setserviceinp .el-input-number{width: 135px;text-align: left;}
	.setserviceinp .el-input-number .el-input__inner{text-align: left;}
</style>
